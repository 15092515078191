.achievement {
  &__item {
    box-shadow: 5px 5px $color-secondary;
    background-color: $bg-one;
    &__image {
      height: 100%;
      object-fit: cover;
    }
  }

  .card {
    box-shadow: 2px 0px 15px 5px $color-shadow;
    border: none;
    &:hover {
      box-shadow: 4px 0px 25px 10px $color-shadow;
    }
  }

  &__content {
    position: relative;
    overflow: hidden;
    padding: 30px 10px 10px 0px;
    &__icon {
      img {
        position: absolute;
        opacity: 0.3;
        top: 0;
        right: 0;
      }
    }
    h3 {
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 0;
    }
  }
}

.bg-one {
  .achievement__item {
    background-color: $color-light;
  }
}

html body div#root div.section-wrapper section#achievements.section-padding.achievement.bg-one div.container div.row div.col-xl-6.offset-xl-3.col-lg-10.offset-lg-1 div.section-title-center.text-center h2 span {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
}